:root {
  --hover-colour: #ff2626;
  --delete-colour: #e61c1c;
  --button-colour: #000;
  --hover-colour-mono: #333;
  --app-height: 100%;
  --title-color: #00e;
  --orange: #ffa500;
  --green: #008000;
  --red: #f00;
  --grey: rgba(0, 0, 0, 0.175);
  --dashboard-blue: #2f7ae9;
  --dashboard-grey: #e8e8f0;
  --dashboard-green: #33c32e;
  --dashboard-red: #e21a1a;
  --dashboard-test-colour: black;
}

.App {
  position: relative;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-text-colour {
  color: #e0e0e0 !important;
}

.nav-text-colour :hover {
  color: #fff !important;
}

.login-form-title {
  text-align: center;
  font-weight: 700;
}

.login-form-box {
  max-width: 400px;
  margin-top: 80px;
}

.login-submit-button {
  background-color: var(--button-colour);
  border: none;
  font-weight: 600;
}

.login-submit-button:hover {
  background-color: var(--hover-colour);
  border: none;
}

.login-submit-button:focus {
  background-color: var(--hover-colour);
  border: none;
}

.login-submit-button:active {
  background-color: var(--hover-colour) !important;
  border: none;
}

.forgot-password-text {
  color: var(--hover-colour-mono);
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: var(--hover-colour-mono);
  -webkit-text-decoration-color: var(--hover-colour-mono);
}

.forgot-password-text:hover {
  color: var(--hover-colour);

  text-decoration: underline;
  text-decoration-color: var(--hover-colour);
  -webkit-text-decoration-color: var(--hover-colour);
}

.content-wrap {
  padding-bottom: 2.5rem; /* Footer height */
}

.footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}

.page-margin {
  margin-top: 2%;
}

.page-title {
  font-weight: 700;
  margin-bottom: 2%;
  font-size: xx-large;
}

.text-link {
  color: inherit;
  text-decoration: inherit;
}

.text-link:hover {
  color: inherit;
  text-decoration: inherit;
}

.tour-box-title {
  font-weight: 600;
  font-size: large;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.tour-box-text {
  font-weight: 400;
  font-size: medium;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 0 !important;
}

.tour-box-date {
  font-weight: 400;
  font-size: small;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-right: 10px;
  padding: 0 !important;
}

.section-header {
  font-weight: 700;
  font-size: large;
  margin-bottom: 5px;
}

.subsection-header {
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: medium;
}

.section-text {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: medium;
}

.white-icon {
  color: #fff;
}

.navbar-toggler {
  border: #000;
}

.navbar-toggler:focus {
  color: #fff;
  box-shadow: 0 0 0 2px;
}

.navbar-toggler:hover {
  color: #fff !important;
  box-shadow: 0 0 0 2px;
}

.navigation-item {
  text-decoration: none;
  color: #fff !important;
  font-size: large;
  font-weight: 600;
  background-color: #000;
}

.navigation-item:hover {
  text-decoration: none;
  color: #fff !important;
  text-decoration: underline;
  background-color: #000;
}

.navigation-item:focus {
  text-decoration: none;
  color: #fff !important;
  text-decoration: underline;
}

.navigation-item a {
  text-decoration: none;
  color: #fff !important;
  font-size: large !important;
  font-weight: 700 !important;
  background-color: #000;
  transform: scale(1) !important;
}

.navigation-item:hover a :not(.dropdown-item) {
  color: #fff !important;
  background-color: #000 !important;
  text-decoration: underline;
}

.navigation-item:focus a {
  color: #fff !important;
  text-decoration: underline;
}

.client-box {
  margin: 5px;
}

.client-box-company {
  font-size: larger;
  font-weight: 700;
}

.delete-button {
  background-color: var(--hover-colour);
  border: none;
  font-weight: 600;
}

.delete-button:hover {
  background-color: var(--delete-colour);
  border: none;
}

.delete-button:focus {
  background-color: var(--delete-colour);
  border: none;
}

.delete:active {
  background-color: var(--delete-colour) !important;
  border: none;
}

.grow:hover {
  color: var(--hover-colour) !important;
}
.grow {
  cursor: pointer;
}

.mouse-click {
  cursor: pointer;
}

.icon-plain {
  color: #333;
}

.icon-clicked {
  color: #407af7;
}

.editable-box {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.editable-box:focus {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid var(--hover-colour);
  border-radius: 0;
}

.tab-bar {
  color: #000 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 3px solid var(--hover-colour);
}

.nav-tabs {
  color: #333;
}

.nav-link {
  color: #333;
  font-weight: 700;
}

.nav-link:hover {
  font-weight: 700;
  color: var(--hover-colour);
}

.nav-link.active {
  font-weight: 700;
  color: var(--hover-colour) !important;
}

@media only screen and (max-width: 600px) {
  .content {
    margin-top: 10px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

.spinner-colour {
  color: var(--hover-colour);
}

.selected-tour {
  border-bottom: 5px solid var(--hover-colour) !important;
}

.grow-2:hover {
  transform: scale(1.1);
}
.grow-2 {
  cursor: pointer;
}

.file-upload {
  display: block;
  position: relative;
  cursor: pointer;
}

.file-upload:hover .space-image {
  opacity: 0.7;
}

.file-upload:hover .file-upload-content {
  opacity: 1;
}

.file-upload-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--hover-colour-mono);
  font-size: 50px;
  opacity: 0;
}

.file-upload-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--hover-colour-mono);
  font-size: 50px;
}

.placeholder-element {
  color: #333;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: larger;
  font-weight: 600;
}

.add-on-title {
  color: #333;
  text-align: center;
  margin-bottom: 15px;
  font-size: x-large;
  font-weight: 600;
}

.add-on-icon {
  padding: 10;
  margin-bottom: 10px;
  max-width: 50;
  max-height: 50px;
}

.add-on-card {
  margin-top: 10px;
  padding: 10px;
  border-bottom: 2px solid var(--hover-colour);
  height: 200px;
}

.red {
  background-color: red !important;
}

.forgot-password-description {
  text-align: center;
  margin-top: 29px;
  margin-bottom: 25px;
}

.branded-tour-iframe {
  display: block; /* iframes are inline by default */
  height: 100vh; /* Set height to 100% of the viewport height */
  width: 100vw; /* Set width to 100% of the viewport width */
  border: none;
  position: relative;
}

.branded-tour-brand {
  position: absolute;
  top: 80px;
  left: 15px;
}

.add-on-icon-big {
  padding: 10;
  margin-bottom: 10px;
  max-width: 100px;
  max-height: 100px;
}

.ad-banner {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.ad-banner-image {
  max-width: 100%;
}

.ad-content {
  max-width: 90%;
}

.ad-content-box-black {
  padding: 5%;
  background-color: #000;
  color: white;
  max-width: 100%;
  margin: 0;
  align-items: center;
}

.ad-content-box-white {
  padding: 5%;
  background-color: #fff;
  color: black;
  max-width: 100%;
  margin: 0;
  align-items: center;
  border-left: 5px dashed black !important;
  border-right: 5px dashed black !important;
}

.ad-content-text {
  font-size: large;
  text-align: justify;
}

.ad-content-small {
  font-size: medium;
  text-align: justify;
}

.ad-inquire-box {
  justify-content: center;
  text-align: center;
  flex: content;
  padding: 2%;
  background-color: #fff;
  margin: 0;
  border-left: 5px dashed black !important;
  border-right: 5px dashed black !important;
  border-bottom: 5px dashed black !important;
  margin-bottom: 10px;
}

.full-width {
  width: 40%;
  padding: 1%;
}

.aviation-welcome {
  font-weight: 600;
  font-size: large;
  margin-top: 3%;
  margin-bottom: 20%;
}

.aviation-welcome-box {
  padding: 20px;
  border: 2px solid black;
  border-radius: 2%;
}

.no-tour-text {
  text-align: center;
  font-weight: 600;
  font-size: large;
}

.no-tour-box {
  min-height: 200px;
}

.tour-image-upload-box {
  max-height: 180px;
  max-width: 260px;
}

.aviation-tour-image-box-uploading {
  padding: 1%;
  margin-top: 5px;
  border: 2px solid black;
}

.aviation-tour-image-box-complete {
  padding: 1%;
  margin-bottom: 5px;
  border: 2px solid rgb(50, 208, 79);
}
.aviation-tour-image-text {
  font-weight: 600;
  margin: 5px;
  margin-left: 10px;
  font-size: medium;
}

.green-tick {
  color: rgb(50, 208, 79);
  font-size: xx-large;
}

.link-style {
  text-decoration: none;
}

.tour-image-div {
  height: 350px;
  width: 100%;
  border: 1px solid var(--hover-colour-mono);
}

.test {
  background-color: red;
}

.aviation-welcome-box-container {
  margin: 0px !important;
}

.tab-end {
  margin-left: auto;
  margin-right: 0;
  text-align: right;
}

.pre-line {
  white-space: pre-line;
}

.list-category {
  font-size: larger;
  font-weight: 900;
}

.company-box-title {
  font-size: large;
  font-weight: 700;
  color: var(--title-color);
}

.company-box-text {
  font-size: medium;
  font-weight: 500;
}

.staff-box-title {
  font-size: large;
  font-weight: 700;
  color: var(--title-color);
}

.redIcon {
  color: var(--red);
}

.orangeIcon {
  color: var(--orange);
}

.greenIcon {
  color: var(--green);
}

.card-image-underline {
  border-bottom: 1px solid var(--bs-card-border-color);
}

.dashboard-box-small-black {
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  height: 150px;
  cursor: pointer;
}

.dashboard-box-small {
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  height: 150px;
  cursor: pointer;
}

.dashboard-box-small-green {
  border: 1px solid var(--dashboard-green);
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  height: 150px;
  cursor: pointer;
}

.dashboard-box-small-red {
  border: 1px solid var(--dashboard-red);
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  height: 150px;
  cursor: pointer;
}

/* .dashboard-box-small-black {
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
} */
.dashboard-box-small-black:hover .small-box-text {
  color: var(--hover-colour) !important;
}

.dashboard-box-small-black:hover .small-box-icon {
  color: var(--hover-colour) !important;
}

.dashboard-box-small-black:hover .small-box-value {
  color: var(--hover-colour) !important;
}

.dashboard-box-large {
  border: 1px solid black;
  margin-bottom: 20px;
  border-radius: 5px;
  min-height: 267px;
}

.small-box-text {
  font-size: 20px;
  font-weight: 700;
  color: var(--dashboard-test-colour);
}
.small-box-value {
  font-size: 40px;
  text-align: end;
  font-weight: 700;
  color: var(--dashboard-test-colour);
}

.small-box-icon {
  font-size: 80px;
  color: var(--dashboard-test-colour);
}

.small-box-content {
  height: 110px;
}

.dashboard-box-title-black {
  font-weight: 700;
  height: 40px;
  font-size: large;

  width: 100%;
  padding: 5px;
  padding-left: 1em;
  background-color: black;
  color: white;
  border-radius: 2px 2px 0px 0px;
  border: 1px solid black;
}

.dashboard-box-title {
  font-weight: 700;
  height: 40px;
  font-size: large;

  width: 100%;
  padding: 5px;
  padding-left: 1em;
  background-color: black;
  color: white;
  border-radius: 2px 2px 0px 0px;
  border: 1px solid black;
}

.dashboard-box-title-green {
  font-weight: 700;
  height: 40px;
  font-size: large;
  margin-left: 1em;
  width: 100%;
  padding: 5px;
  color: var(--dashboard-green);
  border-radius: 2px 2px 0 0;
}

.dashboard-box-title-red {
  font-weight: 700;
  height: 40px;
  font-size: large;
  margin-left: 1em;
  width: 100%;
  padding: 5px;
  color: var(--dashboard-red);
  border-radius: 2px 2px 0 0;
}

/* .dashboard-box-title-black {
  font-weight: 700;
  height: 40px;
  font-size: large;

  width: 100%;
  padding: 5px;
  padding-left: 1em;
  color: black;
  border-radius: 2px 2px 0 0;
  border-top: 1px solid black;
} */

.h-60 {
  height: 60% !important;
}

.divider-line-left {
  border-left: 1px solid black !important;
}

.billing-box-text {
  font-size: medium;
  font-weight: 500;
}

.billing-box-icon {
  font-size: x-large;
  margin-right: 5px;
  font-weight: 300;
}

.billing-box-link:hover {
  color: var(--hover-colour);
  cursor: pointer;
}

.sort-icon {
  font-size: 30px;
  margin-right: 5px;
  font-weight: 500;
  margin-bottom: auto;
  margin-top: 0.4%;
}

.btn-dropdown:hover {
  color: var(--hover-colour);
  cursor: pointer;
  border: none;
}

.btn-dropdown:focus {
  color: var(--hover-colour);
  cursor: pointer;
  border: none;
  border: 1px solid var(--hover-colour);
}

.btn-dropdown::after {
  border: none;
}

.btn-dropdown {
  border: none;
}

.dropdown-item-selected {
  background-color: var(--hover-colour) !important;
  color: white;
}

.dropdown-item-selected:hover {
  background-color: var(--hover-colour) !important;
  color: white;
}

.document-box-text {
  font-size: large;
  font-weight: 800;
}

.document-box-icon {
  font-size: x-large;
  margin-right: 5px;
  font-weight: 500;
}

.white-divider {
  color: white !important;
  background-color: white !important;
}

.navigation-item div {
  background-color: #000;
}

.price-box {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  border: 1px solid var(--grey);
}

.price-box-main-text {
  font-size: 2.8rem;
  font-weight: 700;
}

.price-box-title {
  font-size: 1.3rem;
  font-weight: 600;
}

.price-box-subtitle {
  font-size: 1rem;
  font-weight: 400;
}

.billing-table {
  border: 1px solid var(--grey);
  border-radius: 10px !important;
  border-collapse: separate !important;
  border-spacing: 0px;
  padding: 0px;
}

.billing-table thead {
  border: none;
}

.billing-table th:nth-child(1) {
  border-radius: 10px 0px 0px 0px !important;
  text-align: left;
}

.billing-table th {
  text-align: center;
  color: black;
  background-color: #e9ecef;
  padding: 10px;
  border-bottom: 1px solid var(--grey);
}

.billing-table th:nth-last-child(1) {
  text-align: right;
  border-radius: 0px 10px 0px 0px !important;
}
.billing-table td:nth-child(1) {
  text-align: left;
}

.billing-table td {
  text-align: center;
  padding: 10px;
}

.billing-table td:nth-last-child(1) {
  text-align: right;
}

.order-camera-form {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

.form-title {
  font-size: xx-large;
  margin: 10px;
}

.form-text {
  font-size: large;
  margin: 10px;
}

.box-black {
  /* background-color: var(--dashboard-blue); */
  height:calc(100% - 40px);
}

.box-green {
  background-color: var(--dashboard-green);
}

.box-red {
  background-color: var(--dashboard-red);
}

.company-logo-image {
  /* object-fit: cover; */
  height: 80%;
  margin: auto;
}



